enum FILTER_FIELD_KEY {
  ApplicationIds = 'ids',
  ApplicantIds = 'applicantIds',
  TenderingIds = 'tenderingIds',
  SupplierIds = 'supplierIds',
  StatusIds = 'statusIds',
  NoFeedback = 'noFeedback',
}

export { FILTER_FIELD_KEY }
