
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import { mdiMagnify, mdiPlus, mdiFilter, mdiChevronUp, mdiChevronDown, mdiArrowLeftThin } from '@mdi/js'
import { isEqual } from 'lodash-es'

import CommonTableView from '@/components/common/CommonTableView.vue'
import CommonExportMenu from '@/components/common/CommonExportMenu.vue'

import { useNotify } from '@/store'

import { useNoteDialog } from '@/composables/useNoteDialog'
import { useTaskDialog } from '@/composables/useTaskDialog'
import { useFilter } from '@/composables/useFilter'

import { handleError } from '@/utils/handleError'
import { setSort } from '@/utils/manipulateTableSort'
import { hasSufficientRights } from '@/utils/hasSufficientRights'
import { dateDashNotationToDot } from '@/utils/convertDate'
import { reverseObject } from '@/utils/reverseObject'

import {
  useDeleteApplication,
  useExportApplications,
  useGetApplications,
  useGetApplicationsBasic,
} from '@/api/application'
import { useCreatePerson } from '@/api/person'
import { useGetTenderingsBasic } from '@/api/tendering'
import { useGetApplicantsBasic } from '@/api/applicant'
import { useGetEnumItemsBasic } from '@/api/enumItem'
import { useGetSuppliersBasic } from '@/api/supplier'

import { ApplicationOutput } from '@/api/types/application'
import { ApplicantOutput } from '@/api/types/applicant'
import { PersonInput } from '@/api/types/person'
import { FilterDataTypes, FilterField, OptionalFiltersParam } from '@/composables/types/useFilter'
import { FILTER_FIELD_KEY } from '@/views/acquisition/views/applications/types'
import { Rights } from '@/api/types/right'
import { dateDotNotationToDash } from '@/utils/convertDate'

export default defineComponent({
  name: 'Applications',
  components: {
    CommonTableView,
    CommonExportMenu,
    AddEditApplicationDialog: () =>
      import('@/views/acquisition/views/applications/components/AddEditApplicationDialog.vue'),
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
    CommonTasksDialog: () => import('@/components/common/CommonTasksDialog.vue'),
    CommonNotesDialog: () => import('@/components/common/CommonNotesDialog.vue'),
    CommonInfoDialog: () => import('@/components/common/CommonInfoDialog.vue'),
    ApplicationsFilterBar: () =>
      import('@/views/acquisition/views/applications/components/ApplicationsFilterBar.vue'),
  },
  setup: (_, { root }) => {
    const { addNotification } = useNotify()

    const isForwardedFromTimeline = ref(Boolean(root.$route.params.ids))

    const TABLE_HEADERS = [
      {
        text: root.$t('acquisition.applications.table.col.title.id'),
        value: 'id',
        sortable: true,
      },
      {
        text: root.$t('acquisition.applications.table.col.title.applicant.firstName'),
        value: 'applicant.firstName',
        sortable: false,
      },
      {
        text: root.$t('acquisition.applications.table.col.title.applicant.lastName'),
        value: 'applicant.lastName',
        sortable: false,
      },
      {
        text: root.$t('acquisition.applications.table.col.title.tendering'),
        value: 'tendering.name',
        sortable: false,
      },
      {
        text: root.$t('acquisition.applications.table.col.title.supplier'),
        value: 'applicant.supplier.name',
        sortable: false,
      },
      {
        text: root.$t('acquisition.applications.table.col.title.createdAt'),
        value: 'createdAt',
        sortable: true,
      },
      {
        text: root.$t('acquisition.applications.table.col.title.status'),
        value: 'status.name',
        sortable: false,
      },
      {
        text: root.$t('acquisition.applications.table.col.title.interviewDate'),
        value: 'interviewDate',
        sortable: false,
      },
      {
        text: root.$t('acquisition.applications.table.col.title.feedback'),
        value: 'feedback',
        sortable: true,
      },
      hasSufficientRights(Rights.FINANCIAL_READ) && {
        text: root.$t('acquisition.applications.table.col.title.offerPrice'),
        value: 'offerPrice',
        sortable: true,
      },
      {
        text: root.$t('acquisition.applications.table.col.title.tasksCount'),
        value: 'tasksCount',
        sortable: false,
      },
      {
        text: root.$t('acquisition.applications.table.col.title.notesCount'),
        value: 'notesCount',
        sortable: false,
      },
      {
        text: '',
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG: (keyof ApplicationOutput)[] = [
      'createdBy',
      'createdAt',
      'updatedBy',
      'updatedAt',
      'statusHistory',
    ]

    const isAddDialogOpen = ref(false)

    const isEditMode = ref(false)

    const activeApplication = ref<ApplicationOutput | null>(null)

    function onClickEdit(_, { item: application }: { item: ApplicationOutput }): void {
      if (
        (hasSufficientRights(Rights.APPLICATION_UPDATE_ALL) || hasSufficientRights(Rights.APPLICATION_UPDATE_OWN)) &&
        hasSufficientRights(Rights.BASIC_READ)
      ) {
        isEditMode.value = true

        isAddDialogOpen.value = true

        activeApplication.value = { ...application }
      }
    }

    const isDeleteDialogOpen = ref(false)

    function onClickDelete(application: ApplicationOutput): void {
      activeApplication.value = { ...application }

      isDeleteDialogOpen.value = true
    }

    const { deleteApplication } = useDeleteApplication()

    async function onDeleteApplication(): Promise<void> {
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await deleteApplication(activeApplication.value!.id)
      } catch (error: unknown) {
        handleError(error)
      }

      isDeleteDialogOpen.value = false

      activeApplication.value = null

      debouncedCb.value()
    }

    const isRowInfoDialogOpen = ref(false)

    function onClickInfo(application: ApplicationOutput): void {
      activeApplication.value = cloneDeep(application)

      if (activeApplication.value.statusHistory) {
        activeApplication.value.statusHistory = reverseObject(activeApplication.value.statusHistory)
      }

      isRowInfoDialogOpen.value = true
    }

    function onCloseAddEditDeleteInfoDialog(): void {
      isAddDialogOpen.value = false

      isDeleteDialogOpen.value = false

      isRowInfoDialogOpen.value = false

      isEditMode.value = false

      activeApplication.value = null
    }

    const {
      exec: getTenderingsBasic,
      data: tenderings,
      isLoading: isLoadingTenderingsBasic,
    } = useGetTenderingsBasic()

    const { exec: getSuppliersBasic, data: suppliers, isLoading: isLoadingSuppliersBasic } = useGetSuppliersBasic()

    const {
      exec: getApplicantsBasic,
      data: applicants,
      isLoading: isLoadingApplicantsBasic,
    } = useGetApplicantsBasic()

    const {
      exec: getEnumItemsBasic,
      data: enumItemsBasic,
      isLoading: isLoadingEnumItemsBasic,
    } = useGetEnumItemsBasic()

    const {
      exec: getApplicationsBasic,
      data: applicationsBasic,
      isLoading: isLoadingApplicationsBasic,
    } = useGetApplicationsBasic()

    const isFilterDropdownOpen = ref(false)

    function onToggleFilterDropdown() {
      if (!isFilterDropdownOpen.value) {
        isFilterDropdownOpen.value = true

        if (
          !tenderings.value?.length &&
          !applicants.value?.length &&
          !suppliers.value?.length &&
          !enumItemsBasic.value?.length &&
          !applicationsBasic.value?.length
        ) {
          getTenderingsBasic()
          getApplicantsBasic()
          getSuppliersBasic()
          getEnumItemsBasic({ params: { enumItemAssignment: 'APPLICATION_STATUS' } })
          getApplicationsBasic()
        }
      } else {
        isFilterDropdownOpen.value = false
      }
    }

    const FILTER_FIELDS = ref<FilterField[]>([
      {
        key: FILTER_FIELD_KEY.ApplicationIds,
        value: null,
        label: root.$t('acquisition.applications.filters.applicationIds'),
        items: computed(() => applicationsBasic.value ?? []),
        isLoading: computed(() => isLoadingApplicationsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.ApplicantIds,
        value: null,
        label: root.$t('acquisition.applications.filters.applicant'),
        items: computed(() => applicants.value ?? []),
        isLoading: computed(() => isLoadingApplicantsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.TenderingIds,
        value: null,
        label: root.$t('acquisition.applications.filters.tendering'),
        items: computed(() => tenderings.value ?? []),
        isLoading: computed(() => isLoadingTenderingsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.SupplierIds,
        value: null,
        label: root.$t('acquisition.applications.filters.supplier'),
        items: computed(() => suppliers.value ?? []),
        isLoading: computed(() => isLoadingSuppliersBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.StatusIds,
        value: null,
        label: root.$t('acquisition.applications.filters.status'),
        items: computed(() => enumItemsBasic.value ?? []),
        isLoading: computed(() => isLoadingEnumItemsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.NoFeedback,
        value: null,
        label: root.$t('acquisition.applications.filters.feedback'),
        items: [],
        dataTyp: FilterDataTypes.Boolean,
      },
    ])

    const { debouncedCb, vuetifyTableOptions, paginationParams, filterFieldsObject, isInit } = useFilter(
      FILTER_FIELDS.value as FilterField[],
      init
    )

    watch(
      filterFieldsObject,
      (newVal, oldVal) => {
        isInit.value = false

        if (!isEqual(newVal, oldVal)) {
          debouncedCb.value()
          vuetifyTableOptions.value.page = 1
        }
      },
      { deep: true }
    )

    const { createPerson } = useCreatePerson()

    async function onClickImportApplicant(): Promise<void> {
      try {
        Object.entries(activeApplication.value?.applicant as ApplicantOutput).forEach(
          ([key, value]) =>
            value &&
            value.id &&
            activeApplication.value?.applicant &&
            (activeApplication.value.applicant[key] = value.id)
        )

        const applicant = Object.fromEntries(
          Object.entries(activeApplication.value?.applicant as ApplicantOutput).reduce((acc, item) => {
            if (item[0] !== 'id') {
              acc.set(item[0], item[1])
            }
            return acc
          }, new Map())
        )

        applicant && (await createPerson(applicant as PersonInput))

        addNotification({
          text: root.$t('acquisition.applications.actions.applicantImported') as string,
          timeout: 3000,
          type: 'success',
        })
      } catch (error: unknown) {
        handleError(error)
      }
    }

    setSort(vuetifyTableOptions, { by: 'createdAt', desc: true })

    const {
      exec: getApplications,
      data: applications,
      paginationResponse,
      isLoading: isLoadingApplications,
    } = useGetApplications()

    const totalApplications = computed(() => paginationResponse.value.totalElements)

    async function init(filterFieldsObject?: OptionalFiltersParam): Promise<void> {
      await getApplications({ params: { ...paginationParams.value, ...filterFieldsObject } })
    }

    const { exportApplications, isLoading: isLoadingExport } = useExportApplications()

    async function onExportApplications(dateRange: { startMonth: string; endMonth: string }) {
      try {
        await exportApplications({
          startMonth: dateDotNotationToDash(dateRange.startMonth),
          endMonth: dateDotNotationToDash(dateRange.endMonth),
        })

        addNotification({
          text: root.$t('misc.export.actions.success') as string,
          timeout: 5000,
          type: 'success',
        })
      } catch (error: unknown) {
        handleError(error)
      }
    }

    const {
      isOpen: isNotesDialogOpen,
      notes: applicationNotes,
      onClickOpenDialog: onOpenNotesDialog,
      onReload: onReloadNotes,
      entityId: notesEntityId,
    } = useNoteDialog<ApplicationOutput>(applications, () => init(filterFieldsObject.value))

    const {
      isOpen: isTasksDialogOpen,
      tasks: applicationTasks,
      onClickOpenDialog: onOpenTasksDialog,
      onReload: onReloadTasks,
      entityId: tasksEntityId,
    } = useTaskDialog<ApplicationOutput>(applications, () => init(filterFieldsObject.value))

    function onClickBack(): void {
      root.$router.push({
        name: 'planning-position-timeline',
      })
    }

    return reactive({
      icons: {
        mdiMagnify,
        mdiPlus,
        mdiFilter,
        mdiChevronUp,
        mdiChevronDown,
        mdiArrowLeftThin,
      },
      constants: {
        Rights,

        TABLE_HEADERS,

        PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG,

        FILTER_FIELDS,
      },
      state: {
        isAddDialogOpen,

        applications,

        isEditMode,
        activeApplication,

        isDeleteDialogOpen,

        isRowInfoDialogOpen,

        isTasksDialogOpen,
        applicationTasks,
        tasksEntityId,

        isNotesDialogOpen,
        applicationNotes,
        notesEntityId,

        isLoadingApplications,

        isFilterDropdownOpen,

        totalApplications,
        vuetifyTableOptions,

        isLoadingExport,

        isForwardedFromTimeline,
      },
      listeners: {
        onCloseAddEditDeleteInfoDialog,

        onClickEdit,

        onClickDelete,

        onClickInfo,

        onDeleteApplication,

        onExportApplications,

        onOpenTasksDialog,
        onReloadTasks,

        onOpenNotesDialog,
        onReloadNotes,

        onClickImportApplicant,

        onToggleFilterDropdown,

        onClickBack,
      },
      functions: {
        debouncedCb,

        hasSufficientRights,
        dateDashNotationToDot,
      },
    })
  },
})
