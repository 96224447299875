function reverseObject(obj: { [key: string]: string }): { [key: string]: string } {
  const newObj = {}

  Object.keys(obj)
    .reverse()
    .forEach((key) => {
      newObj[key] = obj[key]
    })

  return newObj
}

export { reverseObject }
